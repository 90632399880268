<template>
  <div class="qz-quiz__wrapper" :class="{'finish-step' : stat.levels == stat.level}" key="t1">
    <aside v-if="formData && stat.level" class="qz-quiz__left">
      <div class="qz-quiz__left__content">
        <div class="qz-progress">
          <div class="qz-progress__item" :style="{width: 100 / stat.levels + '%'}" v-for="(item, index) in stat.levels" :class="{active : stat.level >= index + 1}"></div>
        </div>
      </div><!--end qz-quiz__left__content -->
      <div class="qz-step-bg">
        <div class="qz-step-bg__item" v-for="(item, index) in stat.levels" :class="['bg-' + (index + 1), {'active' : stat.level == index + 1}]"></div>
      </div>
      <div class="qz-question-wrapper">
        <div v-if="formData" class="qz-question">
          <div class="qz-step-counter">Вопрос {{ stat.level }} из {{ stat.levels }}</div>
          <div v-if="formData.heading" class="qz-form-title">{{ formData.heading }}</div>
        </div>
      </div>
      
      <!-- div v-if="stat.level" class="content__progressBox">
        <div class="pRel">
          <div class="medium text_md progress__text layout pAbs">{{ stat.level }}/{{ stat.levels }}</div>
          <svg viewBox="0 0 32 32" class="progress__container">
            <circle class="progress__circleEmpty" r="16" cx="16" cy="16"></circle>
            <circle class="progress__circleFull" r="16" cx="16" cy="16" :stroke-dasharray="progressBar(stat.progress)" ></circle>
          </svg>
        </div>
      </div -->
    </aside>
    <main class="qz-quiz__main" :class="{finish : formData && !stat.level}">
      <div v-if="formData && stat.level" class="qz-form">
        <div class="qz-form-wrapper">
          <div class="qz-form__content">
            <template v-if="formData.fields">
              <dynamic-form/>
            </template>
            <template v-else-if="formData.component">
              <component :is="formData.component"/>
            </template>
          </div>
        </div>
      </div><!--end qz-form -->
        <component v-else-if="formData && !stat.level" :is="formData.component"/>
      <div class="bg-kvartir"></div>
    </main>
  </div>
  <!-- div v-else-if="formData" class="listing__layoutAll growOn pRel" key="t2">
    <component :is="formData.component"/>
  </div -->
</template>

<script>
  import Quiz from '../quiz/App'
  import DynamicForm from '@/modules/quiz-landing/components/DynamicFormComponent'
  import PriceForm from '@/modules/quiz-landing/components/PriceFormComponent'
  import PriceFormRadio from '@/modules/quiz-landing/components/PriceFormRadioComponent'
  import SendForm from '@/modules/quiz-landing/components/SendFormComponent'
  import FinishingFormComponent from '@/modules/quiz-landing/components/FinishingFormComponent'

  export default {
    mixins: [Quiz],
    components: {
      'dynamic-form': DynamicForm,
      'price-form': PriceForm,
      'price-form-radio': PriceFormRadio,
      'send-form': SendForm,
      'finishing-form': FinishingFormComponent
    },
    methods: {
      progressBar (val) {
        return val + ' 100';
      }
    }
  }
</script>
