<template>
  <form @submit="$event.preventDefault()">
    <div v-for="field in formData.fields" class="form__wrapper">
      <Checkbox
        v-if="formData.type === 'checkbox'"
        :name="formData.name"
        :default-value="field.value"
        :label="field.label"
        v-model="value"
      ></Checkbox>
      <Radio
        v-else-if="formData.type === 'radio'"
        :name="formData.name"
        :default-value="field.value"
        :label="field.label"
        v-model="value"
      ></Radio>
    </div>

    <div class="content__buttonBox">
      <button
        v-if="!isFirst"
        @click="$store.commit('prev')"
        class="button_def btn-prev button__type_2"
      >
        <span class="label-text">Назад</span>
        <span class="label-icon">
              <svg  viewBox="0 0 8 12"  xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99744 0.712235C7.38796 1.10276 7.38796 1.73592 6.99744 2.12645L3.12388 6L6.99744 9.87355C7.38796 10.2641 7.38796 10.8972 6.99744 11.2878C6.60691 11.6783 5.97375 11.6783 5.58322 11.2878L1.00256 6.70711C0.61204 6.31658 0.61204 5.68342 1.00256 5.29289L5.58322 0.712235C5.97375 0.321711 6.60691 0.321711 6.99744 0.712235Z" />
              </svg>
            </span>
      </button>

      <div class="growOn"></div>

      <button
        :disabled="!isValid"
        @click="$store.commit('next')"
        class="button_def btn-next button__type_1"
      >
        <span class="label-text">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</span>
        <span class="label-icon">
          <svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99744 0.712235C7.38796 1.10276 7.38796 1.73592 6.99744 2.12645L3.12388 6L6.99744 9.87355C7.38796 10.2641 7.38796 10.8972 6.99744 11.2878C6.60691 11.6783 5.97375 11.6783 5.58322 11.2878L1.00256 6.70711C0.61204 6.31658 0.61204 5.68342 1.00256 5.29289L5.58322 0.712235C5.97375 0.321711 6.60691 0.321711 6.99744 0.712235Z"/>
          </svg>
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import Checkbox from './forms/Checkbox'
import Radio from './forms/Radio'
import DynamicFormComponent from '@/modules/quiz/components/DynamicFormComponent'

export default {
  name: 'dynamic-form',
  mixins: [DynamicFormComponent],
  components: {
    Checkbox,
    Radio
  }
}
</script>
