<template>
    <fragment>
      <SendFormModal v-show="isShowModal" v-model="isShowModal">
        <div slot="heading" v-html="renderModalHeading" class="modal__title"/>
        <div slot="content">
          <div class="b-btns" v-if="!formOpen">
            <button @click="formOpen = true" class="button_def button__type_2 button--icon">
              <span class="icon">
                <svg  fill="#DB389A" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 308 308">
                    <g id="XMLID_468_">
                      <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                        C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                      <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                         M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                        C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                    </g>
                    </svg>
              </span>
              <span class="label-text">WhatsApp</span>
              
            </button>
            <button @click="formOpen = true" class="button_def button__type_2 button--icon">
              <span class="icon">
                <svg  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#DB389A"/>
                <path d="M13.1014 13.7395C13.6251 14.8303 14.339 15.8527 15.2432 16.7568C16.1473 17.661 17.1697 18.3749 18.2605 18.8986C18.3543 18.9437 18.4012 18.9662 18.4606 18.9835C18.6715 19.045 18.9306 19.0008 19.1092 18.8729C19.1595 18.8369 19.2025 18.7939 19.2885 18.7079C19.5516 18.4448 19.6831 18.3133 19.8154 18.2273C20.3142 17.903 20.9572 17.903 21.456 18.2273C21.5883 18.3133 21.7198 18.4448 21.9829 18.7079L22.1295 18.8545C22.5294 19.2544 22.7294 19.4543 22.838 19.6691C23.054 20.0961 23.054 20.6005 22.838 21.0276C22.7294 21.2423 22.5294 21.4423 22.1295 21.8421L22.0109 21.9608C21.6124 22.3593 21.4131 22.5585 21.1422 22.7107C20.8416 22.8796 20.3747 23.001 20.0299 23C19.7192 22.9991 19.5068 22.9388 19.0821 22.8182C16.7996 22.1704 14.6457 20.948 12.8489 19.1511C11.052 17.3543 9.82961 15.2004 9.18176 12.9179C9.06121 12.4932 9.00093 12.2808 9.00001 11.9701C8.99898 11.6253 9.1204 11.1584 9.28927 10.8578C9.44145 10.5869 9.64072 10.3876 10.0392 9.98908L10.1579 9.87046C10.5577 9.47057 10.7577 9.27062 10.9724 9.16201C11.3995 8.946 11.9039 8.946 12.3309 9.16201C12.5457 9.27062 12.7456 9.47057 13.1455 9.87046L13.2921 10.0171C13.5552 10.2802 13.6867 10.4117 13.7727 10.544C14.097 11.0428 14.097 11.6858 13.7727 12.1846C13.6867 12.3169 13.5552 12.4484 13.2921 12.7115C13.2061 12.7975 13.1631 12.8405 13.1271 12.8908C12.9992 13.0694 12.955 13.3285 13.0165 13.5394C13.0338 13.5988 13.0563 13.6457 13.1014 13.7395Z" fill="#fff"/>
              </svg>
              </span>
              <span class="label-text">Телефон</span>
              
              </button>
          </div>
          <form v-if="!success && formOpen" novalidate @submit.prevent="checkForm" class="modal__form-box">
            <div class="modal__form-container">
        
              <div class="modal__form-wrapper" :class="{ 'has-error': errors.has('username') }">
                <input
                    type="text"
                    v-model="username"
                    name="username"
                    v-validate="'min:2|max:50'"
                    class="modal__form text_md"
                    placeholder="Как вас зовут?"
                />
                <div v-if="errors.has('username')" class="modal__form-error">
                  Имя должно содержать от 2 до 50 символов
                </div>
              </div>
        
              <div class="modal__form-wrapper" :class="{ 'has-error': errors.has('phone') }">
                <input
                    v-model="phone"
                    name="phone"
                    type="text"
                    v-phone
                    maxlength="18"
                    v-validate="{ required: true, regex: phoneRegex }"
                    class="modal__form"
                    placeholder="Номер телефона*"
                    autocomplete="off"
                    @keyup="eventLastLetter"
                />
                <div v-if="errors.has('phone')" class="modal__form-error">
                  Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx
                </div>
              </div>
            </div>
      
            <div v-if="error" class="alert alert-danger" v-html="error"></div>
      
            <button
                :disabled="loader"
                type="submit"
                class="button_def button__type_1"
            >
              Отправить
            </button>
          </form>
        </div>
      </SendFormModal>
      
      <div v-if="0" class="qz-finish-result">
        <div class="qz-finish__item-wrapper col-1">
          <div class="qz-finish__b-title">
            <div class="num">{{ result.counts.flats }}</div>
            <div class="text">новостроек по вашему <br>запросу</div>
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-2">
          <div class="qz-finish__item qz-finish__item--square-bg">
            <div class="img" style="background-image: url(/images/quiz/item-bg-1.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-2">
          <div class="qz-finish__item qz-finish__item--square-bg">
            <div class="img" style="background-image: url(/images/quiz/item-bg-2.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-4">
          <div class="qz-finish__item qz-finish__item--square-img">
            <div class="img" style="background-image: url(/images/quiz/item-bg-3.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-4">
          <div class="qz-finish__item qz-finish__item--square-img">
            <div class="img" style="background-image: url(/images/quiz/item-bg-4.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-2">
          <div class="qz-finish__item qz-finish__item--square-img2">
            <div class="img" style="background-image: url(/images/quiz/item-bg-5.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
      </div><!--end qz-finish-result -->
      
        <!-- div class="pRel heightAll listing__layoutPart">
            <div class="listing__complete pAbs">
                <img width="24" src="/images/quiz/icon-complete.svg" alt="">
            </div>
            <div class="flex-wrapper flexWrap">
                <div class="text_slg bold listing__title" v-html="renderHeading"/>
                <button v-if="success" class="button_def button__size button__type_1 button__again text_md medium"
                        @click="goHome()">Начать заново
                </button>
            </div>

            <div class="content__wrapper heightAll">
                <div class="content__form">
                    <div class="content__formWrapper">
                        <list-result :class="{'listing--blur2' : !success }" class="listing--blur" />
                        
                    </div>
                </div>
            </div -->
          
            <!--<BuildList :list="result.novos" />-->

            <!-- div class="listing__gradient"></div>
        </div -->

    </fragment>
</template>
<style scoped>
.modal__formBox {
  max-width: 660px;
  margin: 0 auto;
}
</style>
<script>
  import {TextHelper} from '@/helpers/TextHelper'
  import SendFormComponent from '@/modules/quiz/components/SendFormComponent'
  import SendFormModal from '@/modules/quiz-landing/components/SendFormModal'
  import BuildList from '@/modules/quiz-landing/components/BuildListComponent'
  import ListResult from '@/modules/quiz-landing/components/ListResult'
  import {Fragment} from 'vue-fragment'
  export default {
    name: 'send-form',
    components: {SendFormModal, BuildList, Fragment, ListResult},
    mixins: [SendFormComponent],
    data: () => ({
      isShowModal: false,
      formOpen: false,
    }),
    mounted() {
      // modal will be showing in 2 sec
      setTimeout(() => this.isShowModal = true, 1000);

//    var id = document.querySelector('[ya-counter]');
//
//    if (id) {
//      window['yaCounter' + id.getAttribute('ya-counter')].reachGoal('quiz_request');
//    }
    },
    methods: {
      triggerEvent() {
      }
    },
    computed: {
      renderHeading() {
        // const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
        const build = TextHelper.declensionWords(this.result.counts.flats, ['квартира', 'квартиры', 'квартир']);
      },
      renderModalHeading() {
        if (this.success) {
          // не закрываем после отправки
          // this.isShowModal = false;
        }
        let flats = this.result.counts.flats > 15 ? this.result.counts.flats : 20;
        // const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
        const build = TextHelper.declensionWords(flats, ['квартира', 'квартиры', 'квартир']);
        // const find = TextHelper.declensionWords(this.result.counts.builds, ['найдена', 'найдено', 'найдено']);
        const find = TextHelper.declensionWords(flats, ['подобрана', 'подобрано', 'подобрано']);
        const text = `Для вас ${find} ${flats} ${build}. `;

        return !this.success
          ? text + `Оставьте свои контакты, чтобы увидеть все результаты`
          : '<div class="modal-thanks">Спасибо! <br>Скоро с Вами свяжется наш специалист.</div>'
      }
    },
  }
</script>
