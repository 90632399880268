<template>
    <form @submit="$event.preventDefault()">
        <div class="form__wrapper">
            <div class="form__mediaBox image-checkbox">
                <CheckboxImage
                    :name="formData.name"
                    :default-value="1"
                    label="С отделкой"
                    image="/images/quiz/with2.jpg"
                    v-model="value"
                ></CheckboxImage>
                <CheckboxImage
                    :name="formData.name"
                    :default-value="0"
                    label="Без отделки"
                    image="/images/quiz/without_finishing2.jpg"
                    v-model="value"
                ></CheckboxImage>
            </div>
        </div>
        <div class="content__buttonBox">
            <button
                v-if="!isFirst"
                @click="$store.commit('prev')"
                class="button_def btn-prev button__type_2"
            >
              <span class="label-text">Назад</span>
              <span class="label-icon">
              <svg  viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99744 0.712235C7.38796 1.10276 7.38796 1.73592 6.99744 2.12645L3.12388 6L6.99744 9.87355C7.38796 10.2641 7.38796 10.8972 6.99744 11.2878C6.60691 11.6783 5.97375 11.6783 5.58322 11.2878L1.00256 6.70711C0.61204 6.31658 0.61204 5.68342 1.00256 5.29289L5.58322 0.712235C5.97375 0.321711 6.60691 0.321711 6.99744 0.712235Z" />
              </svg>
            </span>
            </button>

            <div class="growOn"></div>

            <button
                :disabled="!isValid"
                @click="$store.commit('next')"
                class="button_def btn-next button__type_1"
            >
              <span class="label-text">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</span>
              <span class="label-icon">
                <svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99744 0.712235C7.38796 1.10276 7.38796 1.73592 6.99744 2.12645L3.12388 6L6.99744 9.87355C7.38796 10.2641 7.38796 10.8972 6.99744 11.2878C6.60691 11.6783 5.97375 11.6783 5.58322 11.2878L1.00256 6.70711C0.61204 6.31658 0.61204 5.68342 1.00256 5.29289L5.58322 0.712235C5.97375 0.321711 6.60691 0.321711 6.99744 0.712235Z"/>
                </svg>
              </span>
            </button>
        </div>
    </form>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import CheckValueMixin from '@/modules/quiz/mixins/CheckValueMixin'
  import Checkbox from './forms/Checkbox';
  import Radio from './forms/Radio';
  import CheckboxImage from './forms/CheckboxImage';

  export default {
    name: 'dynamic-form',
    mixins: [CheckValueMixin],
    components: {
      Checkbox,
      Radio,
      CheckboxImage
    },
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst'
      }),
      ...mapState({
        form: 'form',
        step: 'step'
      }),
      value: {
        get() {
          return this.form[this.formData.name];
        },
        set(value) {
          this.$store.commit('setValue', {
            name: this.formData.name,
            value,
          });
        }
      },
      isValid() {
        const thisValue = this.form[this.formData.name];
        if (this.formData.type === 'checkbox' && thisValue) {
          return thisValue.length > 0;
        } else {
          return thisValue;
        }
      }
    }
  }
</script>
